import React from 'react'
import logo from '../Assets/Images/logo.png'
import useWindowDimensions from '../Hooks/useWindowDimensions'

export default function Logo() {
  const { windowWidth } = useWindowDimensions();

  console.log(windowWidth,'windowwidth')
  return (
    windowWidth > 830 ? <div className='acst-logo'>
          <img src={logo} alt="" srcset="" />
      </div> : <></>
  )
}
