import { useState, useRef, useEffect } from 'react';
import { Link, useSearchParams } from "react-router-dom";

import VideoThumbnail from '../Assets/Images/video-thumbnail.png';
// import VideoThumbnailMobile from '../Assets/Images/video-thumbnail-mobile.png';
// import PlayButton from '../Assets/Images/play-button.png';
import PlayButton from '../Assets/Images/playbtn.png';
import Clock from '../Assets/Images/clock.png';
import { Close } from "@mui/icons-material";
import useWindowDimenions from "../Hooks/useWindowDimensions";
import '../App.scss';

const Start = props => {

  const [isLoading, setIsLoading]         = useState(true);
  const [videoThumbnailImg, setVideoThumbnailImg] = useState(VideoThumbnail);

  const { windowWidth } = useWindowDimenions();
  console.log(windowWidth);

  // useEffect(() => {
  //   if(windowWidth < 831) setVideoThumbnailImg(VideoThumbnail);
  //   else setVideoThumbnailImg(VideoThumbnail);
  // }, [windowWidth])

  const [searchParams, setSearchParams]   = useSearchParams();
  const [playingVideo, setPlayingVideo]   = useState(false);
  const [startPlaying, setStartPlaying]   = useState(false);
  searchParams.get("leader");
  const vidRef = useRef(null);

  useEffect(() => {
		// document.body.style.backgroundColor = "#EEEEEE";
	},[]);

  console.log("searchParams", searchParams.get("leader"));
  console.log("searchParams", searchParams.get("user"));






  const assessmentLink = () => {
    // if(!searchParams) return "/assessment";
    // else return "/assessment?" + searchParams;
    return "/assessment?" + searchParams;
  }

  const preAssessmentLink = () => {
    if(searchParams == "") return "/before-you-start";
    else return "/before-you-start?" + searchParams;
  }

  const playVideo = (e) => {
    console.log(vidRef.current);
    // vidRef.current.play();
    // setPlayingVideo(true);
    // if(!startPlaying) {
    //   setStartPlaying(true);
    // }
    if(windowWidth > 830) {
      // document.getElementsByClassName("intro")[0].style.opacity = "0";
      document.getElementsByClassName("intro")[0].style.width = "0%";
      document.getElementsByClassName("intro")[0].style.padding = "0";
      document.getElementsByClassName("intro")[0].style.overflow = "hidden";
      document.getElementsByClassName("video")[0].style.width = "100%";
      document.getElementsByClassName("video")[0].style.margin = "0 auto";
      document.getElementsByClassName("video")[0].style.backgroundColor = "#004266";
      document.getElementsByClassName("video")[0].style.borderRadius = "0";
    }
    vidRef.current.play();
    setPlayingVideo(true);
    if(!startPlaying) {
      setStartPlaying(true);
    }
  }

  const pauseVideo = (e) => {
    console.log(vidRef.current);
    // vidRef.current.pause();
    // setPlayingVideo(false);
    vidRef.current.pause();
    if(windowWidth > 830) {
      document.getElementsByClassName("intro")[0].style.width = "44%";
      document.getElementsByClassName("intro")[0].style.padding = "0 3%";
      document.getElementsByClassName("intro")[0].style.overflow = "hidden";
      // document.getElementsByClassName("intro")[0].style.opacity = "1";
      document.getElementsByClassName("video")[0].style.width = "50%";
      document.getElementsByClassName("video")[0].style.margin = "0 auto";
      document.getElementsByClassName("video")[0].style.backgroundColor = "#004266";
      document.getElementsByClassName("video")[0].style.borderTopLeftRadius = "10px";
      document.getElementsByClassName("video")[0].style.borderBottomLeftRadius = "10px";
    }
    setPlayingVideo(false);
  }

  const handleVideoPlaybackEnd = () => {
    setPlayingVideo(false);
    setStartPlaying(false);
    if(windowWidth > 830) {
      document.getElementsByClassName("intro")[0].style.width = "44%";
      document.getElementsByClassName("intro")[0].style.padding = "0 3%";
      document.getElementsByClassName("intro")[0].style.overflow = "hidden";
      // document.getElementsByClassName("intro")[0].style.opacity = "1";
      document.getElementsByClassName("video")[0].style.width = "50%";
      document.getElementsByClassName("video")[0].style.margin = "0 auto";
      document.getElementsByClassName("video")[0].style.backgroundColor = "#004266";
      document.getElementsByClassName("video")[0].style.borderTopLeftRadius = "10px";
      document.getElementsByClassName("video")[0].style.borderBottomLeftRadius = "10px";
    }
    showThumbnail();
    showPlayButton();
  }

  const showThumbnail = () => {
    if(playingVideo) {
      return <img alt="thumbnail" className="thumbnail" src={videoThumbnailImg} style={{ opacity: '0' }} 
      onClick={(e) => pauseVideo(e)} />;
    }
    // if you want to see the thumbnail image when paused instead of video 
    // else return <img alt="thumbnail" className="thumbnail" src={videoThumbnailImg} />
    else {
      if(startPlaying) {
        return null
      } else {
        return <img alt="thumbnail" className="thumbnail" src={videoThumbnailImg} />
      }
    }
  }

  const showPlayButton = () => {
    if(playingVideo) {
      return null
    } else {
      return <img alt="play button" className="playbtn" src={PlayButton} onClick={(e) => playVideo(e)} />
    }
  }

  const vidsrcURL = "";

  return (
    <div className="container start">
      {/* <div className="video">
        <video ref={vidRef} onEnded={handleVideoPlaybackEnd}>
          <source
            src="https://40parables-assets.s3.amazonaws.com/TP3-Assessment-Video.mp4"
            type="video/mp4"
            />
        </video>
        {showThumbnail()}
        {showPlayButton()}
        {playingVideo && <Close className="close-video" style={{ position: "absolute", height: "20px", width: "20px", color: "#434343bb", opacity: 0.75, zIndex: 99, padding: "15px", top: "5px", cursor: "pointer", 
        right: windowWidth > 1100 ? "70px" : "5px" }} onClick={(e) => pauseVideo(e)} />}
      </div> */}
      <div className="intro">
        <h1>Are you and your team aligned about your ChMS?</h1>
        {/* <h2>
        1. Take this 3-minute ChMS test<br/>
        2. Invite your team to take it too<br/>
        3. See all results on 1 dashboard<br/>
        4. Align your team around action<br/>
        </h2> */}
        <div className="intro-point-list-container">
          <ol className={`intro-points-list ${searchParams.get("leader") ? 'leader-intro-point-list' : ''}`}>
          {searchParams.get("leader") ? 
            <>
              <li>Take this 3-minute ChMS test</li>
              <li>View your results instantly</li>
              <li>Begin to align around action with your team</li>
            </> : <>
              <li>Take this 3-minute ChMS test</li>
              <li>Invite your team to take it, too</li>
              <li>See all results on 1 dashboard</li>
              <li>Align your team around action</li>
            </>}
          </ol>
        </div>
        <div className="completion">
          <Link to={assessmentLink()} className="cta">
            Get Started
          </Link>
          <div className='time-estimate'>
            <img className='clock-icon' src={Clock} alt="estimated time" /> <span>{searchParams.get("leader") ? "3 min" : "3 min"}</span>
          </div>
        </div>
        {/* <div className="completion">Estimated time for completion: 3 min</div> */}
      </div>
    </div>
  );
}
export default Start;