import React from 'react';
import { Link } from "react-router-dom";

// import Logo from '../../Assets/Images/logo.png';
import leftLogo from '../../Assets/Images/left-logo.png';
import leftLogoMobile from '../../Assets/Images/left-logo-mobile.png';
import bleatLogo from '../../Assets/Images/logo-right.png';
import bleatLogoMobile from '../../Assets/Images/bleat-logo-mobile.png';
import useWindowDimensions from '../../Hooks/useWindowDimensions';

const Header = props => {

  const { windowWidth } = useWindowDimensions();

  return (
    <>
      <header>
        <div className="header-wrapper">
        
          {/* {windowWidth > 830 ? <Link to="/start" className="logo"><img src={leftLogo} alt="Logo" /></Link> : <Link to="/start" className="logo"><img src={leftLogoMobile} alt="Logo" /></Link>} */}

          {/* {windowWidth > 830 ? <Link to="/start" className="logo"></Link> : <Link to="/start" className="logo"></Link>} */}

          {windowWidth <= 830 ? <div className="logo-left">
            <img src={leftLogoMobile} alt="ACST Logo" />
          </div> : <div className="logo-left"></div>
          }
          
          <div className="assessment-title desktop" id="assessment-title">
            <p className="logo-text">Church Management Software Assessment</p>
            {/* <p className="logo-subtext">ASSESSMENT</p> */}
          </div>

          {/* <div className="logo-right"></div> */}

          { windowWidth > 830 ?
            <div className="bleat-logo"><img src={bleatLogo} alt="bleat logo" height="70px" width="auto" /></div>
            : <div className="bleat-logo"></div>
          }

        {/* {windowWidth <= 830 ?
          (<div className="bleat-logo-mobile">
            <img src={bleatLogoMobile} alt="bleat logo" height="43px" width="auto" />
          </div>) : (<div className="bleat-logo"><img src={bleatLogo} alt="bleat logo" height="57px" width="50px" /></div>)} */}

        </div>

      </header>
    </>
  )
}

export default Header;
