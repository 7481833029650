import React from 'react'
import { Link, useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import analyzeIcon from '../Assets/Images/analyze.png';
import alignmentIcon from '../Assets/Images/alignment.png';
import directionIcon from '../Assets/Images/direction.png';
import Testimonial from './Testimonial';

const InviteInfo = (props) => {

  const { id } = useParams();

  return (
    <div className="invite-container">
      <div className="invite-container-spacer">
        <h3>Invite your team to take this test</h3>
        <p>Discover what your team thinks about your ChMS so that you can all align around what's best for your church.</p>

        <div className="invite-reasons">
          <div className="reason">
            <img src={analyzeIcon} alt="insights icon" /><br />
            <div className="height30"></div>
            <span>Analyze</span><br />
            Understand different perspectives from your team
            </div>

          <div className="reason">
            <img src={alignmentIcon} alt="alignment icon" /><br />
            <div className="height30"></div>
            <span>Align</span><br />
             Align around an action that best serves your church
            </div>

          <div className="reason">
            <img src={directionIcon} alt="direction icon" /><br />
            <div className="height30"></div>
            <span>Advance</span><br />
            Ensure your tech tools are right for your goals
          </div>

        </div>

        <div className="cta-btn">
          <Link to={"/invite-team/" + id} target="_blank">
            <Button>INVITE YOUR TEAM</Button>
          </Link>
        </div>

        {/* <Testimonial /> */}


      </div>
      
      {/* <div className="testimonial-invite">
        <p>Invite your team to take this test along with you and begin to align around the vision for your church's mission!</p>
        <div className="cta-btn">
          <Link to={"/invite-team/" + id} target="_blank">
            <Button>INVITE YOUR TEAM</Button>
          </Link>
        </div>
      </div> */}
      
    </div>
  );
}

export default InviteInfo;
