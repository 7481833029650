import React, { useState, useEffect, useContext } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { useParams } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate, useSearchParams } from "react-router-dom";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import PreAdditionalQuestionContext from '../Store/PreAdditionalQuestionContext';

import prevArrow from '../Assets/Images/prev-arrow.svg';
import nextArrow from '../Assets/Images/next-arrow.svg';
import nextArrowWhite from '../Assets/Images/next-arrow-white.svg';

import '../App.scss';

const PreAdditionalQuestions = (props) => {

  const normalColor = "#343333";
  const activeColor = "#009DF1";
  const newActiveColor = "#ed2028";

  let navigate = useNavigate();
  const { id } = useParams();
  const addCtx 	    = useContext(PreAdditionalQuestionContext);
  console.log(addCtx);
  const [searchParams, setSearchParams] = useSearchParams();

  const [isSaving, setIsSaving]           = useState(false);
  const [isLoading, setIsLoading]         = useState(false);
  const [isFormValid, setIsFormValid]     = useState(false);
  const [selectedValue, setSelectedValue] = useState();

  useEffect(() => {
    if(addCtx.answers[0]) setSelectedValue({value: addCtx.answers[0], label: addCtx.answers[0]});
  }, [addCtx.answers])

  const [formFields, setFormFields] = useState({
    field1: addCtx.answers[0], field2: addCtx.answers[1], field3: addCtx.answers[2], 
    field4: addCtx.answers[3], field5: addCtx.answers[4], field6: addCtx.answers[5]
  })
  
  const [formFieldsErr, setFormFieldsErr] = useState({
    field1Err: false, field2Err: false, field3Err: false, 
    field4Err: false, field5Err: false, field6Err: false
  })
  
  const updateFormFields = (e) => {
    console.log(e.target.name);
    console.log(e.target.value);
    if (e.target.name == "field2") addCtx.updateAnswer(2, e.target.value);
    if (e.target.name == "field3") addCtx.updateAnswer(3, e.target.value);
    if (e.target.name == "field4") addCtx.updateAnswer(4, e.target.value);
    if (e.target.name == "field5") addCtx.updateAnswer(5, e.target.value);
    if (e.target.name == "field6") addCtx.updateAnswer(6, e.target.value);
    setFormFields((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
    checkFormValid();
  }

  const updateFormFieldsErrors = (field, bool) => {
    setFormFieldsErr((prevState) => ({ ...prevState, [field]: bool }))
  }

  console.log("formFields", formFields);
  console.log("selectedValue", selectedValue);
  console.log("formFieldsErr", formFieldsErr);

  const updateSelectField = (selectedOption) => {
    // console.log(event.target.value);
    console.log("updateSelectField selectedOption", selectedOption);
    setSelectedValue(selectedOption);
    addCtx.updateAnswer(1, selectedOption.value);
    setFormFields((prev) => ({ ...prev, field1: selectedOption.value }))
    checkFormValid();
  }

  const checkFormValid = () => {
    if (formFields?.field1 && formFields?.field2 && formFields?.field3 && formFields?.field4 && formFields?.field5 && formFields?.field6) {
      setIsFormValid(true);
    } else setIsFormValid(false);
  }

  const showErrors = () => {
    for (const [key, field] of Object.entries(formFields)) {
      if(!field) updateFormFieldsErrors(`${key}Err`, true);
    }
  }

  const submitToApi = () => {

    let apiCall = '/v1/assessment/' + id + '/additionalForm';

    let jsonBody = JSON.stringify({
      details: {
        "field1": formFields?.field1,
        "field2": formFields?.field2,
        "field3": formFields?.field3,
        "field4": formFields?.field4,
        "field5": formFields?.field5,
        "field6": formFields?.field6,
      },
      assessmentId: process.env.REACT_APP_CLIENT_ID,
      formVersion: 2
    });

    setIsLoading(true);
    fetch(process.env.REACT_APP_API_URI + apiCall, {
      method: 'PUT',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: jsonBody
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status == "success") {
        setIsLoading(false);
        navigate("/details/" + id + "?" + searchParams, { replace: true });
      } else {
        // setOpenSnackbar(true);
      }
    }).catch((err) => {
      console.error("Error submitting church context form data...", err);
      setIsLoading(false);
    })

    // remove this after updating API endpoint 
    // navigate("/details/" + id + "?" + searchParams, { replace: true });

  }

  const handleSubmit = () => {
    setIsSaving(true);
    
    if (formFields?.field1 && formFields?.field2 && formFields?.field3 && formFields?.field4 && formFields?.field5 && formFields?.field6) {
      submitToApi();
    } else {
      showErrors();
      setIsSaving(false);
    }
  }

  const showButton = () => {
    if (isSaving) {
      return <div className="button-progress"><CircularProgress style={{ color: '#FFFFFF' }} size={23} /></div>
    }
    return "SHOW MY RESULTS";
  }

  const showHintText = (field) => {
    if (field == "field1") {
      if (formFieldsErr?.field1Err) {
        return <div className="error-text">Please select your current ChMS.</div>
      }
    }
    if (field == "field2") {
      if (formFieldsErr?.field2Err) {
        return <div className="error-text">Please confirm the number of staff members using ChMS.</div>
      }
    }
    if (field == "field3") {
      if (formFieldsErr?.field3Err) {
        return <div className="error-text">Please confirm your current weekend attendance.</div>
      }
    }
    if (field == "field4") {
      if (formFieldsErr?.field4Err) {
        return <div className="error-text">Please confirm your weekend attendance goal in 1 year.</div>
      }
    }
    if (field == "field5") {
      if (formFieldsErr?.field5Err) {
        return <div className="error-text">Please confirm your annual church budget.</div>
      }
    }
    if (field == "field6") {
      if (formFieldsErr?.field6Err) {
        return <div className="error-text">Please confirm your annual payment to ChMS.</div>
      }
    }
  }

  const removeHintText = (field, e) => {

    // change active input field title color 
    if (e.target.closest(".form-detail")) {
      document.body.querySelectorAll(".input-title").forEach(elm => {
        elm.style.color = normalColor;
        elm.style.fontWeight = 400;
      })
      let inputField = e.target.closest(".form-detail").querySelector(".input-title");
      inputField.style.color = newActiveColor;
      inputField.style.fontWeight = 700;
    }

    for (const [key, formField] of Object.entries(formFields)) {
      if(field == key.toString()) updateFormFieldsErrors(`${key}Err`, false);
    }

  }

  const prevQuestion = () => {
    // navigate("/assessment-additional/" + id, { replace: true });
    if (!searchParams.get("leader") && [...searchParams]?.length) {
      navigate('/assessment/' + id + '/review?' + searchParams, { replace: true });
    } else {
      navigate('/assessment/' + id + '/review', { replace: true });
    }
  };

  const nextPage = () => {
    handleSubmit();
    // if(!searchParams.get("leader")) {
    //   navigate("/details/" + id + "?" + searchParams, { replace: true });
    // } else navigate("/details/" + id + "?" + searchParams, { replace: true });
  }
  
  const prevPage = () => {
    if(!searchParams.get("leader")) {
      navigate("/pre-additional-info/" + id + "?" + searchParams, { replace: true });
    } else navigate("/assessment-additional/" + id + "?" + searchParams, { replace: true });
  }

  if (isLoading) {
    return <div className="container details loading"><CircularProgress style={{ color: '#ed2028' }} size={60} /></div>
  }

  return (
    <div className="container pre-additional-questions-details">

      <div className="details-container">

        {/* <h1>ChMS/Church Context</h1> */}
        <h1>Tell us a little about your church</h1>
        <div style={{ height: '10px' }}></div>
        {/* <p>Before we get to your report, please confirm<br />your details so that we can field4 you a copy.</p> */}

        <div className="form">

          <div className="form-detail">
            <p className="input-title">Current ChMS:<span className="required-field">*</span></p>

            {/* Added react-select instead of MUI TextField */}
            {/* <Select classNamePrefix="react-select" className="form-details-text react-select" */}
            <CreatableSelect classNamePrefix="react-select" className="form-details-text react-select"
              onCreateOption={(e) => {
                addCtx.setField1Values((prev) => ([ ...prev, { value: e.toString().trim(), label: e.toString().trim() }]));
                updateSelectField({ value: e.toString().trim(), label: e.toString().trim() });
              }}
              options={addCtx.field1Values}
              onFocus={(e) => removeHintText("field1", e)}
              onChange={updateSelectField}
              value={selectedValue}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#ed202833', // option hover color
                  primary75: "#fff",
                  primary50: "#ed202866", // option onclick color 
                  // primary: '#3b3a3a',
                  primary: '#ed2028',
                  neutral0: '#F4F4F4',
                  neutral50: '#A5A5A5', // Placeholder text color
                },
              })}
              placeholder={" - Select your ChMS - "} />
            {showHintText("field1")}
          </div>

          <div className="form-detail">
            <p className="input-title">Number of staff members using ChMS:<span className="required-field">*</span></p>
            <TextField hiddenLabel placeholder="E.g. 10" name="field2" value={formFields?.field2} onFocus={(e) => removeHintText("field2", e)} onChange={(e) => { updateFormFields(e) }} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("field2")}
          </div>

          <div className="form-detail">
            <p className="input-title">Current weekend attendance:<span className="required-field">*</span></p>
            <TextField hiddenLabel placeholder="E.g. 1000" name="field3" value={formFields?.field3} onFocus={(e) => removeHintText("field3", e)} onChange={(e) => { updateFormFields(e) }} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("field3")}
          </div>

          <div className="form-detail">
            <p className="input-title">Weekend attendance goal in 1 year:<span className="required-field">*</span></p>
            <TextField hiddenLabel placeholder="E.g. 5000" name="field4" value={formFields?.field4} onFocus={(e) => removeHintText("field4", e)} onChange={(e) => { updateFormFields(e) }} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("field4")}
          </div>

          <div className="form-detail school-name">
            <p className="input-title">Annual church budget:<span className="required-field">*</span></p>
            <TextField hiddenLabel placeholder="E.g. $10000" name="field5" value={formFields?.field5} onFocus={(e) => removeHintText("field5", e)} onChange={(e) => { updateFormFields(e) }} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("field5")}
          </div>

      
          <div className="form-detail">
            <p className="input-title">Annual payment to ChMS:<span className="required-field">*</span></p>
            <TextField hiddenLabel
              type="text"
              placeholder="E.g. $300"
              className="form-details-text"
              name="field6" value={formFields?.field6}
              onFocus={(e) => removeHintText("field6", e)}
              onChange={(e) => { updateFormFields(e) }}
              fullWidth variant="standard" size="normal"
              InputProps={{ disableUnderline: true }}
              onInput={(e) => {
                return e.target.value = e.target.value?.toString().slice(0, 12)
              }}
            />
            {showHintText("field6")}
          </div>


        </div>
        {/* <div className="details-buttons">
          <div className="back-btn">
            <Button onClick={prevQuestion}>
              <img src={prevArrow} alt="previous button" />&nbsp;&nbsp;
            </Button>
          </div>

          <div className="cta-btn">
            <Button onClick={handleSubmit} disabled={isSaving}>
              {showButton()}
            </Button>
          </div>
        </div> */}

        <div className="step-buttons">
        <Button className="cta-prev" onClick={() => prevPage()}>
          <img src={prevArrow} alt="Previous Page Arrow" />&nbsp;&nbsp;PREV
        </Button>
        <Button className="cta" onClick={() => nextPage()}>NEXT&nbsp;&nbsp;<img src={nextArrowWhite} alt="Previous Page Arrow" /></Button>
      </div>

      </div>

    </div>
  );

}
export default PreAdditionalQuestions;
