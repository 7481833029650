import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link, useParams, useNavigate } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";

import prevArrow from "../Assets/Images/prev-arrow.svg";
import nextArrow from "../Assets/Images/next-arrow.svg";

import testimonialImage from "../Assets/Images/testimonial.jpg";
import scheduledImage from "../Assets/Images/schedule-image.png";
import quotationImage from "../Assets/Images/quotation.svg";

import useWindowDimensions from '../Hooks/useWindowDimensions';
import "../App.scss";
import InviteInfo from "../Components/InviteInfo";
import Testimonial from "../Components/Testimonial";

const Results = (props) => {
  let navigate                    = useNavigate();
  const { id }                    = useParams();
  
  const { windowWidth }           = useWindowDimensions();
  const [isLoading, setIsLoading] = useState(true);
  const [isMember, setIsMember]   = useState(false);
  const [name, setName]           = useState("");
  const [score, setScore]         = useState(0);
  const [sections, setSections]   = useState([]);
  const [subScore1, setSubScore1] = useState(0);
  const [subScore2, setSubScore2] = useState(0);
  const [subScore3, setSubScore3] = useState(0);
  const [subScore4, setSubScore4] = useState(0);
  const [subScores, setSubScores] = useState([0, 0, 0, 0]);

	
	useEffect(() => { 
		// document.body.style.backgroundColor = "#007670";
	},[]);

  useEffect(() => {
    fetchResults();
  }, []);

  // to animate subScores on results page 
  useEffect(() => {
    const timer = setTimeout(() => {
      setSubScores([subScore1, subScore2, subScore3, subScore4]);
    }, 250);
    return () => {
      clearTimeout(timer);
    };
  }, [subScore1, subScore2, subScore3, subScore4])

  const fetchResults = () => {
    fetch(process.env.REACT_APP_API_URI + "/v1/assessment/" + id, {
      method: "GET",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status === "success") {
          console.log(data);
          setIsLoading(false);
          setName(data.name);
          setScore(data.score);
          setSections(data.sections);
          setSubScore1(data.subScore1);
          setSubScore2(data.subScore2);
          setSubScore3(data.subScore3);
          setSubScore4(data.subScore4);
          setIsMember(data.isTeamMember);
        } else {
          // setOpenSnackbar(true);
        }
      });
  };

  const showTitleText = () => {
    if (score >= 0 && score <= 50) {
      return <h1><span className="user-name">{name}</span>, your ChMS is Sub-optimal</h1>
    } else if (score > 50 && score <= 80) {
      return <h1><span className="user-name">{name}</span>, your ChMS is Adequate</h1>
    } else if (score > 80) {
      return <h1><span className="user-name">{name}</span>, your ChMS is Perfect</h1>
    }
  };

  const showScoreText = () => {
    if (score >= 0 && score <= 50) {
      if (isMember) {
        return <div className="result-text">
          <p>Your ChMS might not be the best fit for your church.</p>
        </div>
      } else {
        return <div className="result-text">
          <p>Your ChMS might not be the best fit for your church.</p>
          <p>Consider switching to one that serves your church better.</p>
          <p>Now, invite your team to see if their views align with yours.</p>          
        </div>
      }
    } else if (score > 50 && score <= 80) {
      if (isMember) {
        return <div className="result-text">
          <p>Your ChMS might not be the best fit for your church.</p> 
        </div>
      } else {
        return <div className="result-text">
          <p>Your ChMS is fine, but it's not the best fit for your church.</p>
          <p>Consider switching to one that serves your church better.</p>
          <p>Now, invite your team to see if their views align with yours.</p>
        </div>
      }
    } else if (score > 80) {
      if (isMember) {
        return <div className="result-text">
          <p>Your ChMS might not be the best fit for your church.</p>
        </div>
      } else {
        return <div className="result-text">
          <p>Your ChMS seems to be a great fit for your church's needs.</p>
          <p>Take this test annually to ensure that it's always that way.</p>
          <p>Now, invite your team to see if their views align with yours.</p>
        </div>
      }
    }
  };

  const showProgressBarText = (scoreValue) => {
    if (scoreValue >= 95) {
      return "Enjoying health";
    } else if (scoreValue >= 80 && scoreValue < 95) {
      return "Improving health";
    } else if (scoreValue >= 70 && scoreValue < 80) {
      return "Pursuing health";
    } else if (scoreValue < 70) {
      return "Battling unhealthiness";
    }
  }

  const showInvite = () => {
    if (isMember) return null;
    else if (props?.showInvite === false) return null;
    else return ( <InviteInfo /> )
  };

  const showTeamMember = () => {
    if (isMember) {
      return (
        <div className="team-container">
          <h3>Thank you for taking this test!</h3>
          Please get in touch with your leader for the next step.
          <br />
          <br />
        </div>
      );
    } else {
      return null;
    }
  };

  const getLeftMargin = () => {
    if (window.innerWidth < 800 && score > 95) {
      return { left: 90 + "%" };
    } else if (window.innerWidth < 800 && score < 5) {
      return { left: 10 + "%" };
    } else {
      return { left: score + "%" };
    }
  };

  const getBarTextLeft = (score) => {
    if (window.innerWidth < 650 && score > 80) {
      return { left: "-170px" }
    }
    return { left: "-100px" }
  }

  const showSchedule = () => {
    if (isMember) {
      return null;
    } else {
      return (
        <div className="schedule-container">
          <div className="avatar">
            <img src={scheduledImage} />
          </div>
          <h3>Schedule a call with Kim</h3>
          <p>Ready to optimize your board for fundraising or craft and execute an effective annual giving or capital campaign focused on Biblical generosity?</p>
				  <p>Talk with Kim to learn how your Christian school's fundraising can become truly transformative.</p>
          <div className="cta-btn">
            <a href="https://meetings.hubspot.com/kim-jennings" target="_blank">
              <Button>TALK TO KIM</Button>
            </a>
          </div>
        </div>
      );
    }
  };

  const showLegacyInvite = () => {
    if (isMember) {
      return null;
    } else {
      return (
        <div className="invite-legacy-container">
          <div className="invite-legacy">
            <p>Discover what your team thinks about your ChMS so that you can all align around what's best for your church.</p>
            <div className="cta-btn">
              <Link to={"/invite-team/" + id} target="_blank">
                <Button>INVITE YOUR TEAM</Button>
              </Link>
            </div>
          </div>
        </div>
      );
    }
  };

  const sectionsAndSubScores = sections?.map((sec, idx) => ({ section: sec.section, score: subScores[idx] }));
  // remove the last Feedback section from Results and Dashboard
  sectionsAndSubScores.pop();
  console.log("sectionsAndSubScores", sectionsAndSubScores);

  if (isLoading) {
    return (
      <div className="container results loading">
        <CircularProgress style={{ color: '#ed2028' }} size={60} />
      </div>
    );
  }

  return (
    <>
      <div className="container results">
        <div className="details-container">

          {showTitleText()}

          <div className="progress-bar">
            <div className="si_ass_result">
              <div className="si_ass_progress">
                <div className="progress-line" style={{ left: score + '%' }}>
                  {/* <div className="progress-text" style={ windowWidth < 651 && score < 30 ? { left: '-50px', width: '10ch', top: '-85px' } : getBarTextLeft(score)}>{showProgressBarText(score)}</div> */}
                </div>
                <span className="msi_score" style={getLeftMargin()}>{score}%</span>
              </div>
              <div className="pervalue">
                <h4 className="nimp1" style={{ minWidth: '0%' }}></h4>
                <h4 className="nimp" style={{ minWidth: '50%' }}></h4>
                <h4 className="mexpe" style={{ minWidth: '30%' }}></h4>
                <h4 className="eexpe" style={{ minWidth: '20%' }}></h4>
                {/* <h4 className="eexpe1" style={{ minWidth: '20%' }}></h4> */}
                {/* <h4 className="eexpe2" style={{ minWidth: '18%' }}></h4> */}
                {/* <h4 className="vexpe" style={{ minWidth: '5%' }}></h4> */}
              </div>
              
              
              <div className="si_pro_header">
                <h4 className="nimp" style={{ minWidth: '0%' }}></h4>
                <h4 className="mexpe" style={{ minWidth: '50%' }}></h4>
                <h4 className="eexpe" style={{ minWidth: '30%' }}></h4>
                {/* <h4 className="eexpe1" style={{ minWidth: '30%' }}></h4> */}
                {/* <h4 className="eexpe" style={{ minWidth: '30%' }}></h4> */}
              </div>
              
              {/* <div className="pervalue-text"> */}
                {/* <h4 className="nimp" style={{ minWidth: '45%',  }}></h4> */}
                {/* <h4 className="mexpe" style={{ minWidth: '25%',  }}></h4> */}
                {/* <h4 className="eexpe" style={{ minWidth: '30%', f }}></h4> */}
                {/* <h4 className="vexpe" style={{ minWidth: '5%' }}></h4> */}
              {/* </div> */}
             
              <div className="pervalue-text">
                <p className="not-aligned" style={{ marginLeft: windowWidth < 651 ? '15%' : '17.5%', fontWeight: score <= 50 ? 800 : 600 }}>Suboptimal</p>
                {/* <p className="poor-aligned" style={{ marginLeft: '25%', fontWeight: score > 20 && score <= 40 ? 800 : 600 }}>Poorly<br />Aligned</p> */}
                <p className="some-aligned" style={{ marginLeft: windowWidth < 651 ? '56%' : '58%', fontWeight: score > 50 && score <= 80 ? 800 : 600 }}>Adequate</p>
                {/* <p className="well-aligned" style={{ marginLeft: '65%', fontWeight: score > 60 && score <= 80 ? 800 : 600 }}>Well<br />Aligned</p> */}
                <p className="high-aligned" style={{ marginLeft: windowWidth < 651 ? '80%' : '83%', fontWeight: score > 80 ? 800 : 600 }}>Perfect</p>
              </div>

            </div>
          </div>
          {/* <div className="spacer"></div>
          <div className="spacer"></div> */}

          {/* <div className="score-section">
              <div className="score-txt">{subScore2}%</div>
              <div className="score-slider">
                <LinearProgress
                  className="custom-bar"
                  variant="determinate"
                  value={subScore2}
                />
              </div>
              LEADERS
            </div>

            <div className="score-section">
              <div className="score-txt">{subScore3}%</div>
              <div className="score-slider">
                <LinearProgress
                  className="custom-bar"
                  variant="determinate"
                  value={subScore3}
                />
              </div>
              STAFF
            </div> */}

          {showScoreText()}
          <div className="score-section-container">

            {sectionsAndSubScores?.map(sec => {
              return (
              <div key={sec.section} className="score-section">
                <p className="score-txt">{sec.score}%</p>
                <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={sec?.score} /></div>
                <p className="score-name">{sec.section}</p>
              </div>)
            })}

          </div>
        </div>
      </div>

      
      {showInvite()}

      {showTeamMember()}

      <Testimonial/>
      {/* <div className="testimonial-container">

        <div className="testimonial">

          <div className="testi">
            <span className="quotation-mark">“</span>Doing this assessment as a team gave us better insights into our level of alignment with our school board. It also helped us put a plan in place to make our meetings more productive, so that we continue to align on the board's objectives together.<span className="quotation-mark bottom"><div className="quotation-block">“</div></span>
          </div>

          <div className="avatar">
            <img src={testimonialImage} />
          </div>

          <div className="designation">
            Sam Davis<br />
            <span>Lead Pastor, Springs of Life Church</span>
          </div>

        </div>

      </div> */}

      {/* {showSchedule()} */}

      {showLegacyInvite()}

      {/* </div> */}

    </>

  );
};
export default Results;
