import { useState, useEffect, Fragment } from 'react';
// import bleatLogoMobile from '../../Assets/Images/logo-bleat-mobile.svg';
import bleatMobileBlack from '../../Assets/Images/logo-bleat-mobile.svg';
import bleatMobileWhite from '../../Assets/Images/logo-bleat-mobile-white.png';
import bleatLogo from '../../Assets/Images/bleat-logo.png';
import useWindowDimensions from '../../Hooks/useWindowDimensions';
import { useLocation } from "react-router-dom";


const Footer = props => {

  const { windowWidth }        = useWindowDimensions();
  const currentLocation        = useLocation();
  const currentPath            = currentLocation.pathname.split("/")[1];

  const [logo, setLogo]        = useState(bleatLogo);
  
  // console.log(currentLocation);
  // console.log(currentLocation.pathname.split("/")[1]);

  // useEffect(() => {

  //   // paths with dark background
  //   const pathsWithDarkBg = ["results", "invite-team", "invite-team-thanks", "dashboard"];

  //   pathsWithDarkBg.includes(currentPath) ?
  //     setLogo(bleatMobileWhite) : setLogo(bleatMobileBlack);

  // }, [currentLocation, currentPath]);


  return <Fragment>
    <footer>

      {windowWidth >= 830 ? 
        null
        // <div className="bleat-logo">
        //   <img src={logo} alt="bleat logo" height="92px" width="auto" />
        // </div>
      :
      <div className="bleat-logo-mobile">
        <img src={bleatMobileBlack} alt="bleat logo" height="43px" width="auto" />
      </div>
      }

      {/* <div className="bleat-logo-mobile">
        <img src={bleatLogoMobile} alt="bleat logo" height="43px" width="auto" />
      </div> */}

    </footer>
  </Fragment>
}

export default Footer;